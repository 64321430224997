module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"/home/runner/work/b-web/b-web/node_modules/gatsby-plugin-image","id":"d7cc2dc5-f6f7-51bf-8eab-c9b7d6ec9f32","name":"gatsby-plugin-image","version":"2.18.1","modulePath":"/home/runner/work/b-web/b-web/node_modules/gatsby-plugin-image/dist/gatsby-image.js","module":{},"pluginOptions":{"plugins":[],"maxWidth":1200},"nodeAPIs":["createSchemaCustomization","onCreateBabelConfig","onCreateWebpackConfig","preprocessSource"],"browserAPIs":[],"ssrAPIs":["onRenderBody"]}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/b-web/b-web","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[],"resetCSS":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"B-WEB","short_name":"B-WEB","start_url":"/?source=pwa","background_color":"#f7f0eb","theme_color":"#a2466c","display":"standalone","icon":"src/images/b-web-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e2a7bb45888051fc4133c352ddc299ca"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-TPMFQYG11J"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":true,"exclude":["/preview/**"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
